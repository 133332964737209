import React from "react";
import { connect } from "react-redux";
import Carousel from "nuka-carousel";

const Clients = ({ clients }) => {
  clients = clients.map((client) => (
    <div className="wrapper" key={client.id}>
      <img src={client.logo ? client.logo : client.logo_url} alt="" />
    </div>
  ));

  return (
    <div id="clients" className="rn-brand-area rn-section-gapTop pb--200 mx-5">
      <Carousel
        slidesToShow={4}
        cellSpacing={20}
        defaultControlsConfig={{
          prevButtonStyle: {
            opacity: "0",
          },
          nextButtonStyle: {
            opacity: "0",
          },
        }}
        wrapAround
        pauseOnHover
        autoplay
        autoplayInterval={2500}
      >
        {clients}
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clients: state.rootClients.clients,
});

export default connect(mapStateToProps, null)(Clients);
