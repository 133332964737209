// Actions
import { FETCH_HOME_IMAGES } from "../actions/actionTypes";

const initialState = {
  homeImages: [],
  homeImagesLoading: true,
};

export const homeImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOME_IMAGES:
      return {
        ...state,
        homeImages: action.payload,
        homeImagesLoading: false,
      };

    default:
      return state;
  }
};

export default homeImagesReducer;
