import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Actions
import {
  fetchWebsiteDetails,
  fetchWork,
  fetchClients,
  fetchHomeImages,
} from "../redux/actions";

// Components
import Header from "./header/header";
import HeaderSidebar from "./header/headerSidebar";
import Footer from "./footer";
import "../scss/style.scss";

class Layout extends Component {
  componentDidMount() {
    this.props.fetchWebsiteDetails();
    this.props.fetchWork();
    this.props.fetchClients();
    this.props.fetchHomeImages();
  }

  state = { language: localStorage.getItem("language") || "Arabic" };

  handleClick = () => {
    const currentLanguage = localStorage.getItem("language") || "Arabic";
    const newLanguage = currentLanguage === "Arabic" ? "English" : "Arabic";
    this.setState({
      language: newLanguage,
    });
    localStorage.setItem("language", newLanguage);
  };

  render() {
    if (
      this.props.websiteDetailsLoading ||
      this.props.workLoading ||
      this.props.clientsLoading
    )
      return (
        <div className="loading-icon">
          <FontAwesomeIcon icon={faCircleNotch} spin size="6x" />
        </div>
      );

    const { language } = this.state;
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child))
          return React.cloneElement(child, { language: language });
        return child;
      }
    );
    return (
      <div
        className="main-wrapper active-dark container-fluid"
        style={language === "English" ? { letterSpacing: "2px" } : null}
      >
        <Header handleClick={this.handleClick} language={language} />
        <HeaderSidebar handleClick={this.handleClick} language={language} />
        <main>{childrenWithProps}</main>
        <Footer language={language} />
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  websiteDetailsLoading: state.rootWebsiteDetails.websiteDetailsLoading,
  workLoading: state.rootWork.workLoading,
  clientsLoading: state.rootClients.clientsLoading,
  homeImagesLoading: state.rootHomeImages.homeImagesLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWebsiteDetails: () => dispatch(fetchWebsiteDetails()),
    fetchWork: () => dispatch(fetchWork()),
    fetchClients: () => dispatch(fetchClients()),
    fetchHomeImages: () => dispatch(fetchHomeImages()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
