import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Scrollspy from "react-scrollspy";

// Start Header Area
class Header extends Component {
  componentDidMount = () => {
    window.addEventListener("scroll", () => {
      this.setState({
        scroll: window.scrollY > 10,
      });
    });
  };

  state = { scroll: false };

  render() {
    const { scroll } = this.state;
    const websiteDetails = this.props.websiteDetails;
    const arabicView = this.props.language === "Arabic";

    return (
      <>
        <header
          style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
          className={
            scroll
              ? "rn-header header-default header-transparent scrolled d-none d-xl-block"
              : "rn-header header-default header-transparent d-none d-xl-block"
          }
        >
          <div className="header-inner">
            <div className="container">
              <div className="row align-items-center">
                {/* Start Header Left  */}
                <div className="col-lg-3">
                  <div className="header-left">
                    <div className="logo mb-3">
                      <img
                        src={websiteDetails[0].logo}
                        alt=""
                        width={websiteDetails[0].logo_width}
                        height={websiteDetails[0].logo_height}
                      />
                    </div>
                  </div>
                </div>
                {/* End Header Left  */}

                {/* Start Mainmenu Area  */}
                <div className="col-lg-9">
                  <div className="menu_wrapper">
                    <Scrollspy
                      className="mainmenuwrapper"
                      style={
                        arabicView
                          ? { direction: "rtl" }
                          : { justifyContent: "flex-end" }
                      }
                      items={[
                        "home",
                        "about",
                        "portfolio",
                        "clients",
                        "contact",
                      ]}
                      currentClassName="is-current"
                      offset={-200}
                    >
                      <li>
                        <a className="menu-hover-link" href="/#home">
                          <span className="hover-item">
                            <span data-text={arabicView ? "الرئيسية" : "Home"}>
                              {arabicView ? "الرئيسية" : "Home"}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a className="menu-hover-link" href="/#about">
                          <span className="hover-item">
                            <span data-text={arabicView ? "من أنا" : "About"}>
                              {arabicView ? "من أنا" : "About"}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a className="menu-hover-link" href="/#portfolio">
                          <span className="hover-item">
                            <span
                              data-text={arabicView ? "أعمالي" : "Portfolio"}
                            >
                              {arabicView ? "أعمالي" : "Portfolio"}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a className="menu-hover-link" href="/#clients">
                          <span className="hover-item">
                            <span data-text={arabicView ? "عملائي" : "Clients"}>
                              {arabicView ? "عملائي" : "Clients"}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <a className="menu-hover-link" href="/#contact">
                          <span className="hover-item">
                            <span
                              data-text={arabicView ? "تواصل معي" : "Contact"}
                            >
                              {arabicView ? "تواصل معي" : "Contact"}
                            </span>
                          </span>
                        </a>
                      </li>

                      <li>
                        <button
                          className="menu-hover-link"
                          onClick={this.props.handleClick}
                        >
                          <span className="hover-item">
                            <span
                              className="mr-5"
                              style={{
                                fontFamily: arabicView
                                  ? "Open Sans, sans-serif"
                                  : "Cairo, sans-serif",
                              }}
                              data-text={arabicView ? "English" : "عربي"}
                            >
                              {arabicView ? "English" : "عربي"}
                            </span>
                          </span>
                        </button>
                      </li>
                    </Scrollspy>
                  </div>
                </div>
                {/* End Mainmenu Area  */}
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
// End Header Area

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

const mapStateToProps = (state) => ({
  websiteDetails: state.rootWebsiteDetails.websiteDetails,
});

export default connect(mapStateToProps)(Header);
