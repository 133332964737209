import React, { Component } from "react";
import ProjectsList from "./projectsList";

class Portfolio extends Component {
  state = {
    category: "All",
    all: false,
  };

  handleClick = () => this.setState({ all: !this.state.all });

  handleTabSelection = (event) =>
    this.setState({
      category: event.target.name,
    });

  render() {
    const { category, all } = this.state;
    const arabicView = this.props.language === "Arabic";
    const title = arabicView ? "أعمالي المميزة" : "Featured works";

    return (
      <div
        className="portfolio-id"
        id="portfolio"
        style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
      >
        <div className="rn-portfolio-area pt--200 pb--150 bg-color-grey portfolio-style-1">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <div className="section-title">
                  <h3
                    className="title"
                    style={
                      arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                    }
                    align={arabicView ? "right" : "left"}
                  >
                    {title}
                    <span
                      className="bg"
                      style={arabicView ? { right: "0" } : { left: "0" }}
                    >
                      {arabicView ? "معرض الأعمال" : "Portfolio"}
                    </span>
                  </h3>
                </div>
              </div>
            </div>

            <div
              className={
                arabicView
                  ? "row d-flex flex-row-reverse justify-content-center"
                  : "row d-flex justify-content-center"
              }
            >
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link"
                  name="All"
                  onClick={this.handleTabSelection}
                  style={{
                    textDecoration: category === "All" ? "underline" : "none",
                  }}
                >
                  {arabicView ? "الكل" : "All"}
                </button>
              </div>

              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link"
                  name="Pictures"
                  onClick={this.handleTabSelection}
                >
                  {arabicView ? "صور" : "Pictures"}
                </button>
              </div>

              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link"
                  name="Documentaries"
                  onClick={this.handleTabSelection}
                >
                  {arabicView ? "وثائقيات" : "Documentaries"}
                </button>
              </div>

              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link"
                  name="Advertisements"
                  onClick={this.handleTabSelection}
                >
                  {arabicView ? "إعلانات" : "Advertisements"}
                </button>
              </div>

              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-link"
                  name="From the sky"
                  onClick={this.handleTabSelection}
                >
                  {arabicView ? "من السماء" : "From the sky"}
                </button>
              </div>
            </div>

            <ProjectsList
              category={category}
              all={all}
              language={this.props.language}
            />

            <div className="row">
              <div className="col-lg-12">
                <div className="button-group mt--60 justify-content-center">
                  <button className="rn-button" onClick={this.handleClick}>
                    <span>
                      {arabicView ? "مشاهدة جميع الأعمال" : "View All Work"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Portfolio;
