import React from "react";
import { connect } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { Controller, Scene } from "react-scrollmagic";

const About = ({ websiteDetails, language }) => {
  const arabicView = language === "Arabic";

  const title = arabicView ? "سعد طحيطح" : "Saad Tahaitah";
  const subtitle = arabicView
    ? "مصور و صانع أفلام وثائقية"
    : "PHOTOGRAPHER & DOCUMENTARY FILMMAKER";
  const description = arabicView
    ? websiteDetails[0].about_section_arabic_description
    : websiteDetails[0].about_section_description;
  const contactButton = arabicView ? "تواصل معي" : "Contact Me";
  const aboutImage = websiteDetails[0].about_section_image;

  return (
    <div
      className="rb-about-area about-style rn-section-gap bg-color-white"
      style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
      id="about"
    >
      <div className="container">
        <div
          className={
            arabicView
              ? "row d-flex flex-row-reverse row--45 align-items-center"
              : "row d-flex row--45 align-items-center"
          }
        >
          <div className="col-lg-5">
            <div className="thumbnail">
              <div className="trigger" id="trigger2" />
              <Controller>
                <Scene
                  classToggle="animated"
                  triggerElement="#trigger2"
                  triggerHook="onCenter"
                >
                  <div className="rn_surface story-image">
                    <img
                      style={arabicView ? { float: "right" } : null}
                      className="about-images"
                      src={aboutImage}
                      alt=""
                      width="500"
                    />
                  </div>
                </Scene>
              </Controller>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="inner">
              <div className="content">
                <div className="section-title">
                  <div className="title-wrap">
                    <h3
                      style={
                        arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                      }
                      align={arabicView ? "right" : "left"}
                      className="title wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                    >
                      {title}

                      <span
                        className="bg"
                        style={arabicView ? { right: "0" } : { left: "0" }}
                      >
                        {arabicView ? "من أنا" : "About"}
                      </span>
                    </h3>
                    {title && (
                      <h4
                        style={
                          arabicView
                            ? {
                                fontFamily: "Cairo, sans-serif",
                              }
                            : null
                        }
                        align={arabicView ? "right" : "left"}
                        className="subtitle wow fadeInLeft"
                        data-wow-delay="200ms"
                        data-wow-duration="1000ms"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                      ></h4>
                    )}
                  </div>

                  {description && (
                    <TextareaAutosize
                      className="description wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                      readOnly
                      value={description}
                      dir={arabicView ? "rtl" : "ltr"}
                    />
                  )}
                </div>
                <div
                  className="button-group mt--30"
                  style={arabicView ? { justifyContent: "flex-end" } : null}
                >
                  {contactButton && (
                    <a
                      className="rn-button wow fadeInLeft"
                      data-wow-delay="200ms"
                      data-wow-duration="1000ms"
                      href="#contact"
                    >
                      <span>{contactButton}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  websiteDetails: state.rootWebsiteDetails.websiteDetails,
});

export default connect(mapStateToProps)(About);
