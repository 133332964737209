import React from "react";
import { connect } from "react-redux";

// Components
import Projectcard from "./projectCard";

const ProjectsList = ({ category, all, work, language }) => {
  const arabicView = language === "Arabic";
  console.log("===>", category);
  let projects =
    category !== "All"
      ? work.filter((work) => work.category === category)
      : work;
  if (!all) projects = projects.length > 6 ? projects.slice(0, 6) : projects;
  const projectData = projects.map((project) => (
    <Projectcard
      portfolioStyle="portfolio-style-1"
      key={project.id}
      selectedCategory={category}
      project={project}
      language={language}
    />
  ));

  return (
    <div
      className={
        arabicView
          ? "row d-flex flex-row-reverse row--45 mt_dec--30"
          : "row d-flex row--45 mt_dec--30"
      }
    >
      {projectData}
    </div>
  );
};

const mapStateToProps = (state) => ({
  work: state.rootWork.work,
});

export default connect(mapStateToProps, null)(ProjectsList);
