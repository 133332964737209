// Actions
import { FETCH_CLIENTS } from "../actions/actionTypes";

const initialState = {
  clients: [],
  clientsLoading: true,
};

export const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        clientsLoading: false,
      };

    default:
      return state;
  }
};

export default clientsReducer;
