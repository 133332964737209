import React, { Component } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import { BsArrowsFullscreen } from "react-icons/bs";

class projectModal extends Component {
  state = { isOpen: false, index: 0 };

  render() {
    const arabicView = this.props.arabicView;

    const project = this.props.project;
    const id = project.id;
    const videoURL = project.video_url;
    const title = arabicView ? project.arabic_title : project.title;
    const category = project.category;
    const description = arabicView
      ? project.arabic_description
      : project.description;
    const role = arabicView ? project.arabic_role : project.role;
    const arabicCategory =
      category === "Pictures"
        ? "صور"
        : category === "Documentaries"
        ? "وثائقيات"
        : category === "Advertisements"
        ? "إعلانات"
        : category === "From the sky"
        ? "من السماء"
        : "";
    const pictures =
      project.pictures.length > 0
        ? project.pictures.map((pictureObj) => pictureObj.picture)
        : null;
    const picturesCarousel = pictures
      ? pictures.map((picture, index) => (
          <div
            key={index}
            className={!index ? "carousel-item active" : "carousel-item"}
          >
            <img className="image-btn" src={picture} alt={title} />
            <button
              data-dismiss="modal"
              className="btn"
              onClick={() => this.setState({ isOpen: true })}
              title="view in full screen"
            >
              <BsArrowsFullscreen />
            </button>
          </div>
        ))
      : null;
    const carouselIndicators = pictures
      ? pictures.map((picture, index) => (
          <li
            key={index}
            data-target={"#Carousel" + id}
            data-slide-to={index}
            className={!index ? "active" : null}
          ></li>
        ))
      : null;
    const { isOpen, index } = this.state;

    let videoID = "";
    if (videoURL) {
      videoID = videoURL.split("v=")[1];
      const ampersandPosition = videoID.indexOf("&");
      if (ampersandPosition !== -1)
        videoID = videoID.substring(0, ampersandPosition);
    }

    return (
      <div
        id={"Modal" + id}
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header" dir={arabicView ? "rtl" : "ltr"}>
              <h5
                className="modal-title"
                style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
              >
                {title}
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={arabicView ? { margin: "initial" } : null}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p
                style={
                  arabicView
                    ? { textAlignLast: "right", direction: "rtl" }
                    : null
                }
              >
                {arabicView
                  ? "التصنيف: " + arabicCategory
                  : "Category: " + category}

                <br />
                <br />
                {role ? (
                  <>
                    {arabicView ? "دوري: " + role : "My role: " + role}
                    <br />
                    <br />
                  </>
                ) : null}
                {description}
              </p>

              {pictures ? (
                <>
                  <div
                    id={"Carousel" + id}
                    className="carousel slide"
                    data-ride="carousel"
                    data-interval="false"
                  >
                    {pictures.length > 1 ? (
                      <ol className="carousel-indicators">
                        {carouselIndicators}
                      </ol>
                    ) : null}
                    <div className="carousel-inner">{picturesCarousel}</div>
                    {pictures.length > 1 ? (
                      <>
                        <a
                          className="carousel-control-prev"
                          href={"#Carousel" + id}
                          role="button"
                          data-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a
                          className="carousel-control-next"
                          href={"#Carousel" + id}
                          role="button"
                          data-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Next</span>
                        </a>
                      </>
                    ) : null}
                  </div>

                  {isOpen && (
                    <Lightbox
                      mainSrc={pictures[index]}
                      nextSrc={
                        pictures.length > 1
                          ? pictures[(index + 1) % pictures.length]
                          : null
                      }
                      prevSrc={
                        pictures.length > 1
                          ? pictures[
                              (index + pictures.length - 1) % pictures.length
                            ]
                          : null
                      }
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          index:
                            (index + pictures.length - 1) % pictures.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          index: (index + 1) % pictures.length,
                        })
                      }
                    />
                  )}
                </>
              ) : (
                <iframe
                  title={title}
                  allowFullScreen="allowfullscreen"
                  src={"https://www.youtube.com/embed/" + videoID + "?rel=0"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default projectModal;
