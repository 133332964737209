import React from "react";
import { connect } from "react-redux";
import Carousel from "nuka-carousel";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const Home = ({ homeImages, language }) => {
  const arabicView = language === "Arabic";
  const images = homeImages.map((image) => {
    return (
      <div key={image.id} className="home-wrapper">
        <img className="home-image" src={image.picture} alt="" />
        <div className="centered">
          <h3
            className="card-title mb-4"
            style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
          >
            {arabicView ? image.arabic_title : image.title}
          </h3>
          <a
            className="rn-button"
            href="#portfolio"
            style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
          >
            <span>{arabicView ? "مشاهدة المزيد" : "View More"}</span>
          </a>
        </div>
      </div>
    );
  });

  if (homeImages.length === 0) return null;
  return (
    <div id="home" className="home-wrapper">
      <Carousel
        renderCenterLeftControls={({ previousSlide }) => (
          <button className="" onClick={previousSlide}>
            <FaArrowLeft size={45} />
          </button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <button onClick={nextSlide}>
            <FaArrowRight size={45} />
          </button>
        )}
      >
        {images}
      </Carousel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  homeImages: state.rootHomeImages.homeImages,
});

export default connect(mapStateToProps)(Home);
