import React from "react";

// Components
import ProjectModal from "./projectModal";

const Projectcard = ({ language, project, selectedCategory }) => {
  const arabicView = language === "Arabic";
  const pictures =
    project.pictures.length > 0
      ? project.pictures.map((pictureObj) => pictureObj.picture)
      : null;
  const videoURL = project.video_url;
  const title = arabicView ? project.arabic_title : project.title;
  const category = project.category;
  const arabicCategory =
    category === "Pictures"
      ? "صور"
      : category === "Documentaries"
      ? "وثائقيات"
      : category === "Advertisements"
      ? "إعلانات"
      : category === "From the sky"
      ? "من السماء"
      : "";
  const id = project.id;

  let videoID = "";
  if (videoURL) {
    videoID = videoURL.split("v=")[1];
    const ampersandPosition = videoID.indexOf("&");
    if (ampersandPosition !== -1)
      videoID = videoID.substring(0, ampersandPosition);
  }

  return (
    <div className="col-lg-4 col-md-6 col-12">
      <div className="portfolio">
        <div className="thumbnail">
          {pictures ? (
            <button
              className="btn modal-btn"
              data-toggle="modal"
              data-target={"#Modal" + id}
            >
              <img
                className="outside-img"
                src={pictures[0]}
                alt={title}
                width="100%"
              />
            </button>
          ) : (
            <div id="parent">
              <iframe
                title={title}
                src={"https://www.youtube.com/embed/" + videoID}
              />
              <button
                className="btn btn-primary modal-btn"
                data-toggle="modal"
                data-target={"#Modal" + id}
              />
            </div>
          )}
        </div>

        <ProjectModal project={project} arabicView={arabicView} />

        <div className="content">
          <h4
            className="title"
            style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
            align={arabicView ? "right" : "left"}
          >
            {title}
          </h4>
          {selectedCategory === "All" && (
            <p align={arabicView ? "right" : "left"}>
              {arabicView
                ? "التصنيف: " + arabicCategory
                : "Category: " + category}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projectcard;
