import instance from "./instance";
import * as actionTypes from "./actionTypes";

export const fetchWebsiteDetails = () => async (dispatch) => {
  try {
    const res = await instance.get(`/details/`);
    const websiteDetails = res.data;

    dispatch({
      type: actionTypes.FETCH_WEBSITE_DETAILS,
      payload: websiteDetails,
    });
  } catch (error) {
    console.log("Fetch website details error ...");
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchWork = () => async (dispatch) => {
  try {
    const res = await instance.get(`/work/`);
    const work = res.data;

    dispatch({ type: actionTypes.FETCH_WORK, payload: work });
  } catch (error) {
    console.log("Fetch work error ...");
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const fetchClients = () => async (dispatch) => {
  try {
    const res = await instance.get(`/clients/`);
    const clients = res.data;

    dispatch({ type: actionTypes.FETCH_CLIENTS, payload: clients });
  } catch (error) {
    console.log("Fetch clients error ...");
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const addContact = (contactData) => async () => {
  try {
    await instance.post(`/contact/`, contactData);
    return true;
  } catch (error) {
    console.log("Add contact error ...");
    error.response
      ? console.error(error.response.data)
      : console.error(error.response);
    return false;
  }
};

export const fetchHomeImages = () => async (dispatch) => {
  try {
    const res = await instance.get(`/images/`);
    const images = res.data;

    dispatch({ type: actionTypes.FETCH_HOME_IMAGES, payload: images });
  } catch (error) {
    console.log("Fetch home images error ...");
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
