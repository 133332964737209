import React, { Component } from "react";
import { connect } from "react-redux";

// Actions
import { addContact } from "../../redux/actions";

class ContactForm extends Component {
  state = {
    full_name: "",
    email_address: "",
    phone_number: "",
    subject: "",
    message: "",
    response: "",
  };

  handleChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleSubmit = async (event) => {
    event.preventDefault();
    const response = await this.props.addContact(this.state);
    this.setState({
      response,
      full_name: response ? "" : this.state.full_name,
      email_address: response ? "" : this.state.email_address,
      phone_number: response ? "" : this.state.phone_number,
      subject: response ? "" : this.state.subject,
      message: response ? "" : this.state.message,
    });
  };

  render() {
    const {
      full_name,
      email_address,
      phone_number,
      subject,
      message,
    } = this.state;
    const arabicView = this.props.language === "Arabic";

    return (
      <form onSubmit={this.handleSubmit} autoComplete="off">
        <div className="row">
          <div
            className={`form-group col-md-6 ${full_name ? "has-value" : ""}`}
          >
            <input
              type="text"
              name="full_name"
              value={full_name}
              dir={arabicView ? "rtl" : "ltr"}
              onChange={this.handleChange}
              required
            />
            <label style={arabicView ? { right: "25px" } : { left: "25px" }}>
              {arabicView ? "الإسم الكامل" : "Full Name"}
            </label>
          </div>

          <div
            className={`form-group col-md-6 ${
              email_address ? "has-value" : ""
            }`}
          >
            <input
              type="email"
              name="email_address"
              value={email_address}
              onChange={this.handleChange}
              required
            />
            <label style={arabicView ? { right: "25px" } : { left: "25px" }}>
              {arabicView ? "الإيميل" : "Email"}
            </label>
          </div>
        </div>

        <div className="row">
          <div
            className={`form-group col-md-6 ${phone_number ? "has-value" : ""}`}
          >
            <input
              type="tel"
              pattern="\d*"
              title="Only digits are accepted"
              name="phone_number"
              value={phone_number}
              dir={arabicView ? "rtl" : "ltr"}
              onChange={this.handleChange}
              required
            />
            <label style={arabicView ? { right: "25px" } : { left: "25px" }}>
              {arabicView ? "رقم الهاتف" : "Phone Number"}
            </label>
          </div>

          <div className={`form-group col-md-6 ${subject ? "has-value" : ""}`}>
            <input
              type="text"
              name="subject"
              value={subject}
              dir={arabicView ? "rtl" : "ltr"}
              onChange={this.handleChange}
              required
            />
            <label style={arabicView ? { right: "25px" } : { left: "25px" }}>
              {arabicView ? "الموضوع" : "Subject"}
            </label>
          </div>
        </div>

        <div className="row">
          <div className={`form-group col-12 ${message ? "has-value" : ""}`}>
            <textarea
              name="message"
              value={message}
              dir={arabicView ? "rtl" : "ltr"}
              onChange={this.handleChange}
              required
            ></textarea>
            <label
              style={arabicView ? { right: "25px" } : { left: "25px" }}
              htmlFor="message"
            >
              {arabicView ? "اكتب رسالتك هنا" : "Write your message here"}
            </label>
          </div>
        </div>

        <div className="row">
          <div className="form-submit col--md-4 mx-auto">
            <button className="rn-button" type="submit">
              {arabicView ? "إرسال" : "Send Message"}
            </button>
            {this.state.response !== "" && (
              <p
                align="center"
                className={`form-output ${
                  !this.state.response ? "errorMsg" : "success"
                }`}
              >
                {this.state.response
                  ? arabicView
                    ? "تم الإرسال بنجاح"
                    : "Sent successfully"
                  : arabicView
                  ? "حدث خطأ"
                  : "Something went wrong"}
              </p>
            )}
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addContact: (contactData) => dispatch(addContact(contactData)),
  };
};

export default connect(null, mapDispatchToProps)(ContactForm);
