import React from "react";
import { connect } from "react-redux";
import ContactForm from "./contactForm";

const Contact = ({ websiteDetails, language }) => {
  const arabicView = language === "Arabic";

  const description = arabicView
    ? websiteDetails[0].contact_section_arabic_description
    : websiteDetails[0].contact_section_description;

  return (
    <div
      className="contact-wrapper rn-contact-area rn-section-gapBottom pt--200 bg-color-grey"
      style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
      id="contact"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2
                className="title"
                style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
                align={arabicView ? "right" : "left"}
              >
                {arabicView ? "اترك لي رسالة" : "Leave me a message"}

                <span
                  className="bg"
                  style={arabicView ? { right: "0" } : { left: "0" }}
                >
                  {arabicView ? "تواصل معي" : "Contact"}
                </span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row row--45">
          {/* Start Contact Form  */}
          <div
            className="col-12 mt--70 mt_md--30 mt_sm--40 wow fadeInLeft"
            data-wow-delay="200ms"
            data-wow-duration="1000ms"
          >
            <div className="info">
              <p
                style={{
                  textAlignLast: arabicView ? "right" : "left",
                  direction: arabicView ? "rtl" : "ltr",
                }}
              >
                {description}
              </p>
            </div>
            <ContactForm language={language} />
          </div>
          {/* End Contact Form  */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  websiteDetails: state.rootWebsiteDetails.websiteDetails,
});

export default connect(mapStateToProps)(Contact);
