// Actions
import { FETCH_WEBSITE_DETAILS } from "../actions/actionTypes";

const initialState = {
  websiteDetails: [],
  websiteDetailsLoading: true,
};

export const websiteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEBSITE_DETAILS:
      return {
        ...state,
        websiteDetails: action.payload,
        websiteDetailsLoading: false,
      };

    default:
      return state;
  }
};

export default websiteDetailsReducer;
