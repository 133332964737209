import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Scrollspy from "react-scrollspy";

// Start Header Area
const HeaderNoSidebar = ({ websiteDetails, language, handleClick }) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const arabicView = language === "Arabic";

  useEffect(() => {
    if (!isOverlayOpen) {
      document
        .querySelector(".trigger-popup-menu")
        .classList.remove("overlay-wrapper-open");
      document
        .querySelector(".hambergur-menu")
        .classList.remove("hambergur-menu-open");
    }
  });

  const onMenuToggleClick = () => {
    setIsOverlayOpen((prevState) => !prevState);
    document
      .querySelector(".trigger-popup-menu")
      .classList.toggle("overlay-wrapper-open");
    document
      .querySelector(".hambergur-menu")
      .classList.toggle("hambergur-menu-open");
  };

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <>
      <header
        className={
          scroll
            ? "rn-header header-default header-transparent d-block d-xl-none scrolled"
            : "rn-header header-default header-transparent d-block d-xl-none"
        }
      >
        <div className="header-inner">
          {/* Header Logo  */}
          <div className="header-left">
            <div className="logo">
              <div className="logo">
                <img
                  src={websiteDetails[0].logo}
                  alt=""
                  width={websiteDetails[0].logo_width}
                  height={websiteDetails[0].logo_height}
                />
              </div>
            </div>
          </div>
          {/* Main Menu  */}
          <div className="header-right" onClick={onMenuToggleClick}>
            <div className="hambergur-menu">
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </div>
          </div>
          {/* Start Social Area  */}
        </div>
      </header>
      <div className="trigger-popup-menu">
        <div className="menu_full">
          <div className="menu_wrapper">
            <Scrollspy
              className="popup_mainmenu"
              style={
                arabicView
                  ? { direction: "rtl" }
                  : { justifyContent: "flex-end" }
              }
              items={["home", "about", "portfolio", "clients", "contact"]}
              currentClassName="is-current"
              offset={-200}
            >
              <li>
                <a
                  className="menu-hover-link"
                  href="/#home"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span
                      data-text={arabicView ? "الرئيسية" : "Home"}
                      style={
                        arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                      }
                    >
                      {arabicView ? "الرئيسية" : "Home"}
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="menu-hover-link"
                  href="/#about"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span
                      data-text={arabicView ? "من أنا" : "About"}
                      style={
                        arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                      }
                    >
                      {arabicView ? "من أنا" : "About"}
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="menu-hover-link"
                  href="/#portfolio"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span
                      data-text={arabicView ? "أعمالي" : "Portfolio"}
                      style={
                        arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                      }
                    >
                      {arabicView ? "أعمالي" : "Portfolio"}
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="menu-hover-link"
                  href="/#clients"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span
                      data-text={arabicView ? "عملائي" : "Clients"}
                      style={
                        arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                      }
                    >
                      {arabicView ? "عملائي" : "Clients"}
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <a
                  className="menu-hover-link"
                  href="/#contact"
                  onClick={onMenuToggleClick}
                >
                  <span className="hover-item">
                    <span
                      data-text={arabicView ? "تواصل معي" : "Contact"}
                      style={
                        arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                      }
                    >
                      {arabicView ? "تواصل معي" : "Contact"}
                    </span>
                  </span>
                </a>
              </li>

              <li>
                <button className="menu-hover-link" onClick={handleClick}>
                  <span className="hover-item">
                    <span
                      style={{
                        fontFamily: arabicView
                          ? "Open Sans, sans-serif"
                          : "Cairo, sans-serif",
                      }}
                      data-text={arabicView ? "English" : "عربي"}
                    >
                      {arabicView ? "English" : "عربي"}
                    </span>
                  </span>
                </button>
              </li>
            </Scrollspy>
          </div>
          <div className="trigger_closer" onClick={onMenuToggleClick}>
            <span className="text">Close</span>
            <span className="icon"></span>
          </div>
        </div>
      </div>
    </>
  );
};
// End Header Area

HeaderNoSidebar.propTypes = {
  siteTitle: PropTypes.string,
};

HeaderNoSidebar.defaultProps = {
  siteTitle: ``,
};

const mapStateToProps = (state) => ({
  websiteDetails: state.rootWebsiteDetails.websiteDetails,
});

export default connect(mapStateToProps)(HeaderNoSidebar);
