// Actions
import { FETCH_WORK } from "../actions/actionTypes";

const initialState = {
  work: [],
  workLoading: true,
};

export const workReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORK:
      return {
        ...state,
        work: action.payload,
        workLoading: false,
      };

    default:
      return state;
  }
};

export default workReducer;
