import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

const Footer = ({ showBelow, websiteDetails, language }) => {
  const arabicView = language === "Arabic";
  const youtubeLink = websiteDetails[0].youtube_url || "";
  const linkedinLink = websiteDetails[0].linkedin_profile_url || "";
  const twitterLink = websiteDetails[0].twitter_username
    ? "https://twitter.com/" + websiteDetails[0].twitter_username
    : "";
  const instagramLink = websiteDetails[0].instagram_username
    ? "https://www.instagram.com/" + websiteDetails[0].instagram_username
    : "";

  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const ConvertToArabicNumbers = (num) => {
    const arabicNumbers =
      "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
    return num.toString().replace(/[0123456789]/g, (d) => {
      return arabicNumbers[d];
    });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  return (
    <footer
      className="rn-footer-area bg-color-white pt--90 pb--60"
      style={arabicView ? { fontFamily: "Cairo, sans-serif" } : null}
    >
      <div className="footer-wrapper">
        <div className="container">
          <div className={arabicView ? "row d-flex flex-row-reverse" : "row"}>
            {/* Small screens */}
            <div
              className="d-md-none col-lg-4 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
              data-wow-delay="200ms"
              data-wow-duration="0.1s"
            >
              <div className="ft-info text-center">
                <h3
                  style={
                    arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                  }
                  align="center"
                >
                  {arabicView ? "سعد طحيطح" : "Saad Tahaitah"}
                </h3>
                <p align="center">
                  {arabicView
                    ? "مصور و صانع أفلام وثائقية"
                    : "Photographer & Documentary Filmmaker"}
                </p>
              </div>
            </div>

            {/* Large screens */}
            <div
              className="d-none d-md-block col-lg-4 col-md-6 mt_md--30 mt_sm--30 wow fadeInDown my-auto"
              data-wow-delay="200ms"
              data-wow-duration="0.1s"
            >
              <div className="ft-info">
                <h3
                  style={
                    arabicView ? { fontFamily: "Cairo, sans-serif" } : null
                  }
                  align={arabicView ? "right" : "left"}
                >
                  {arabicView ? "سعد طحيطح" : "Saad Tahaitah"}
                </h3>
                <p align={arabicView ? "right" : "left"}>
                  {arabicView
                    ? "مصور و صانع أفلام وثائقية"
                    : "Photographer & Documentary Filmmaker"}
                </p>
              </div>
            </div>

            <div
              className="text-center col-lg-4 col-md-6 col-sm-12 col-12 mt_md--30 mt_sm--30 wow fadeInDown"
              data-wow-delay="200ms"
              data-wow-duration="0.6s"
            >
              <p>
                {arabicView
                  ? "تابعني على مواقع التواصل الإجتماعي"
                  : "Follow Me On Social Media"}
              </p>
              <div className="ft-info d-flex">
                <ul className="social-share text-center mx-auto justify-content-center">
                  {youtubeLink ? (
                    <li>
                      <a target="_blank" rel="noreferrer" href={youtubeLink}>
                        <FaYoutube />
                      </a>
                    </li>
                  ) : null}
                  {linkedinLink ? (
                    <li>
                      <a target="_blank" rel="noreferrer" href={linkedinLink}>
                        <FaLinkedinIn />
                      </a>
                    </li>
                  ) : null}
                  {twitterLink ? (
                    <li>
                      <a target="_blank" rel="noreferrer" href={twitterLink}>
                        <FaTwitter />
                      </a>
                    </li>
                  ) : null}
                  {instagramLink ? (
                    <li>
                      <a target="_blank" rel="noreferrer" href={instagramLink}>
                        <FaInstagram />
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
              <p dir={arabicView ? "rtl" : "ltr"}>
                {arabicView ? (
                  <>
                    حقوق النشر © {ConvertToArabicNumbers(2021)} سعد طحيطح، جميع
                    الحقوق محفوظة.
                    <br />
                    صنع بواسطة{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.devloykw.com/"
                    >
                      Devloy
                    </a>
                  </>
                ) : (
                  <>
                    Copyrights © 2021 Saad Tahaitah, All rights reserved.
                    <br />
                    Made by{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.devloykw.com/"
                    >
                      Devloy
                    </a>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="back-to-top"
        style={arabicView ? { left: "80px" } : { right: "50px" }}
      >
        <div className="backtop" onClick={handleClick}>
          <span className="top"></span>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  websiteDetails: state.rootWebsiteDetails.websiteDetails,
});

export default connect(mapStateToProps)(Footer);
