import React from "react";

import Layout from "./components/layout";
import Home from "./components/home";
import About from "./components/about";
import Project from "./components/portfolio";
import Clients from "./components/clients";
import Contact from "./components/contact";

const App = () => (
  <Layout>
    <Home />
    <About />
    <Project />
    <Clients />
    <Contact />
  </Layout>
);
export default App;
