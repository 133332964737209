import { combineReducers } from "redux";

// Reducers
import clientsReducer from "./clients";
import workReducer from "./work";
import websiteDetailsReducer from "./websiteDetails";
import homeImagesReducer from "./homeImages";

export default combineReducers({
  rootClients: clientsReducer,
  rootWork: workReducer,
  rootWebsiteDetails: websiteDetailsReducer,
  rootHomeImages: homeImagesReducer,
});
